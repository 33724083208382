function InsertPromt() {
    return <div className="form-container">
        <h1>YouTube Link Eingeben</h1>
        <form method="GET" action='/loadVideo' className="row justify-content-center">
            <div className="col-12"><input type="text" pattern="^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$" name="url" placeholder="Video Link" /></div>
            <div className="col-12"><input type="submit" value="Suchen" /></div>
        </form>
    </div>;
}

export default InsertPromt;
