import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../components/ErrorMessage"
import InsertPromt from '../components/InsertPromt';

import './main.css'

function Main () {

    const [isError, setError] = useState(false)
    const [isFetched, setFetched] = useState(false)
    const [apiData, setAPIData] = useState([])
    const [currentList, setCurrentList] = useState([])
    const [selectedList, setSelectedList] = useState("last")

    useEffect(async () => {
        const params = new URLSearchParams(window.location.search)
        let error = params.get('error')
        setError(error)
        getData()
        setCurrentList((selectedList === "last" ? apiData.lastList : apiData.favList))
    }, [])

    let navigate = useNavigate(); 
    const routeChange = (urlToRoute) =>{ 
      navigate(urlToRoute);
    }

    function getData() {
        fetch(process.env.REACT_APP_API + '/getData', {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            setAPIData(res)
            console.log(res)
            if(res.lastList) {
                setCurrentList(res.lastList)
            }
            setFetched(true)
        })
        .catch(err => {
            console.log(err)
            setFetched(true)
        })
    }

    return (
        <div className="main">
            {isError && <ErrorMessage />}
            <InsertPromt />
            <div className="split">
                <div className="line"></div>
            </div>
            <button type="button" className={(selectedList === "last") ? "button active" : "button"} onClick={() => {
                setSelectedList("last");
                setCurrentList(apiData.lastList);
            }}>Letzte</button>
            <button type="button" className={(selectedList === "fav") ? "button active" : "button"} onClick={() => {
                setSelectedList("fav");
                setCurrentList(apiData.favList);
            }}>Favorites</button>
            <h2>{selectedList === "last" ? "Last Queries" : "Favorites"}</h2>
            {!isFetched && <h2>Lade Daten...</h2>}
            {isFetched && !currentList && <h2>Fehler beim Laden der Daten</h2>}
            {isFetched && apiData && currentList && currentList.length === 0 && <h2>{selectedList === "last" ? "Bisher wurde noch nichts gesucht." : "Es gibt noch keine Favoriten."}</h2>}
            <div className="last-queries">
            {isFetched && apiData && currentList && currentList.length > 0 && currentList.map((item, index) => {
                return (
                    <div className="query-element">
                        <img className="video-icon" src={"https://img.youtube.com/vi/" + item.videoID + "/0.jpg"} alt={"Thumbnail of Video " + item.videoId} />
                        <p className="video-title">{item.videoTitle}</p>
                        <p className="video-author">{item.videoAuthor}</p>
                        <button className="load-video-btn" onClick={() => routeChange("/loadVideo?url=" + item.videoURL)} type="button">Load</button>
                    </div>
                )}
            )}
            </div>
        </div>
    )
}

export default Main