import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './views/Main';
import LoadVideo from './views/LoadVideo';

function App() {

  return (
    <div className="dash">
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/loadVideo" element={<LoadVideo />} />
        </Routes>
    </div>
  );
}

export default App;