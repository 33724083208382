import './ErrorMessage.css'

function Footer() {
    return <div class="warning">
        <p>
            <strong>Warning</strong>
            <br />
            Unknown Video. Video konnte nicht gefunden weren. Ist der Link richtig?
        </p>
    </div>;
}

export default Footer;
