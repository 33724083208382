import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

import './loadvideo.css'

function LoadedVideo () {

    const [isFetched, setFetched] = useState(false)
    const [apiData, setAPIData] = useState([])
    const [saveObject, setSaveObject] = useState({})

    let navigate = useNavigate(); 
    const routeChange = (urlToRoute) =>{ 
      navigate(urlToRoute);
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        let vURL = params.get('url')
        if(!vURL) return routeChange('/?error=true')
        getData(vURL);
    }, [])

    function getData(vidURL) {
        fetch(process.env.REACT_APP_API + '/getRawData?url=' + vidURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                return routeChange('/?error=true');
            }
            setAPIData(res)
            setSaveObject(res.saveObject)
            console.log(res)
            setFetched(true)
        })
        .catch(err => {
            console.log(err)
            setFetched(true)
        })
    }

    function pauseOrPlayVid() {
        var vid = document.getElementById("loadedVideo");
        var btn = document.getElementById("pausePlayBtn");
        if(!vid.paused) {
            btn.style.background = "yellow";
            btn.style.color = "black";
            btn.value = "Abspielen"
            vid.pause();
        } else {
            btn.style.color = "white";
            btn.style.background = "var(--bg-dark-color)";
            btn.value = "Pausieren"
            vid.play();
        }
    }

    function addVidToFavs() {
        fetch(process.env.REACT_APP_API + '/toggleStatus', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(saveObject)
        })
        .then(res => res.json())
        .then(res => {
            const temp = apiData
            temp.favList = res.data
            setAPIData(temp)
            console.log(apiData)
            var btn = document.getElementById("addTofav");
            btn.style.background = (apiData.favList.find((el) => el.videoID === saveObject.videoID) ? 'red' : '#1cfc03');
            btn.value = (apiData.favList.find((el) => el.videoID === saveObject.videoID) ? 'Von Favoriten entfernen' : 'Zu Favoriten hinzufügen');
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="main">
            {isFetched && apiData && apiData.favList &&
            <div className="warning yellow">
                <p>
                    <strong>Success!</strong>
                    <br />
                    Der Ton wurde geladen und kann jetzt im Hintergrund abgespielt werden!
                </p>
            </div>
            }
            {!isFetched && <h2>Lade Daten Des Videos...</h2>}
            {isFetched && !apiData?.favList && <h2>Fehler Beim Laden Der Daten (API hat nicht geantwortet)</h2>}
            {isFetched && apiData && apiData.favList &&
            <div className="form-container">
                <audio id="loadedVideo" style={{margin: 'auto'}} preload="none" controls>
                    <source src={apiData.videoURL} type="audio/mpeg"/>
                </audio>
            <h1>Neuen YouTube Link Eingeben</h1>
            <form method="GET" action='/loadVideo' className="row justify-content-center">
                <div className="col-12"><input type="text" pattern="^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$" name="url" placeholder="Video URL" /></div>
                <div className="col-12"><input type="submit" value="Suchen" /></div>
                <div class="col-12"><input onClick={() => pauseOrPlayVid()} id="pausePlayBtn" type="button" style={{background: 'yellow', color: 'black'}} value="Abspielen" /></div>
                <div class="col-12"><input onClick={() => addVidToFavs()} id="addTofav" type="button" style={{background: (apiData.favList.find((el) => el.videoID === saveObject.videoID) ? 'red' : '#1cfc03'), color: 'black'}} value={(apiData.favList.find((el) => el.videoID === saveObject.videoID) ? 'Von Favoriten entfernen' : 'Zu Favoriten hinzufügen')} /></div>
            </form>
        </div>
        }

        </div>
    )
}

export default LoadedVideo